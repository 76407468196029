import { useRef, useEffect } from "react";
import { useColorScheme } from "../../styles/ColorSchemeContext.js";


import Navbar from "../../components/Navbar/Navbar";
import Logo from "../../components/Logo/Logo";
import ProjectDescription from "../../components/ProjectDescription/ProjectDescription.js";


import styles from "./ClientsPage.module.scss";

const ClientsPage = () => {
  const { colorScheme } = useColorScheme();

  const clientsElement = useRef(null);

  const typeText = (textArray, element, speed) => {
    let i = 0;
    element.innerHTML = "";
    const typing = setInterval(() => {
      element.innerHTML += textArray[i];
      i++;
      if (i === textArray.length) {
        clearInterval(typing);
      }
    }, speed);
  }
  
  useEffect(() => {
    const clientsList = [
      "Art Basel",
      "<br>",
      "Byredo",
      "<br>",
      "Extreme Cashmere",
      "<br>",
      "Diptyque",
      "<br>",
      "Lanvin",
      "<br>",
      "Moncler",
      "<br>",
      "Nike",
      "<br>",
      "Porsche",
      "<br>",
      "Soho house",
      "<br>",
      "The North Face",
    ];

      clientsElement.current && typeText(clientsList, clientsElement.current, 20);
  }, [])

  return (
    <main style={{ color: colorScheme.textColor }}>
      <Navbar />

      <p ref={clientsElement} className="popupText"></p>

      <div className={styles.logo} style={{ fill: colorScheme.logoColor }}>
        <Logo />
      </div>
      {colorScheme.description && (
        <ProjectDescription description={colorScheme.description} />
      )}
    </main>
  );
}

export default ClientsPage;